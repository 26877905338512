body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96vh;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 41px;
  width: 100%;
  padding-bottom: 30px;
}

.unlimited-mints {
  background: #fdfdff;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 10px;
  margin: 0;
  text-transform: uppercase;
  color: #0a1812;
  font-weight: 500;
  letter-spacing: 1px;
}

.badge img {
  width: 130px;
}

.header-container {
  align-items: center;
}

.wallet-adapter-button:hover {
  background: #3145B4;
}

.mint-container {
  max-width: 460px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.mint-container > div {
  background-color: rgba(32, 26, 18, 0.68) !important;
  border-radius: 14px !important;
  backdrop-filter: blur(8px);
  border: 1px solid #A7A7D4;
}

.preview-ing {
  width: 100% !important;
  object-fit: contain;
}

.progress-bar {
  max-width: 420px !important;
  margin: 0 auto 30px !important;
}

.mint-btn-container button:not(.wallet-adapter-button):hover {
  background-color: #34cd94 !important;
}

.mint-btn-container button.wallet-adapter-button {
  margin-top: 20px;
}

.mint-btn-container button.wallet-adapter-button,
.wallet-adapter-button {
  background-color: #474645 !important;
}

@media only screen and (min-width: 769px) {
  .header-container {
    justify-content: space-between !important;
  }

  .counter-container .MuiPaper-elevation1 {
    padding: 10px 10px 15px;
    width: calc(20% - 10px);
    background: linear-gradient(0deg, #3ff8a1, #3ff8a1) !important;
    color: #1f4f4e;
    text-transform: uppercase;
    font-size: 0.8rem;
    box-sizing: border-box;
  }

  .counter-container .MuiPaper-elevation1 h1 {
    font-size: 30px;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 20px !important;
  }


  .header-container {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .wallet-connect-container > div {
    font-size: 15px !important;
  }

  .wallet-adapter-button {
    font-size: 14px !important;
    height: 40px !important;
    padding: 0 10px !important;
  }

  .preview-gif-container img {
    height: auto; 
    width: 100%;
  }

  .mint-container h2 {
    font-size: 30px;;
  }

  .price-container {
    transform: scale(0.7) translateX(-14px);
  }

  .mint-container > .MuiPaper-root {
    width: calc(100% - 40px);
    min-width: auto;
  }

  .counter-container .MuiPaper-elevation1 {
    padding: 10px 10px 15px;
    width: calc(25% - 10px);
    background: linear-gradient(0deg, #3ff8a1, #3ff8a1) !important;
    color: #1f4f4e;
    text-transform: uppercase;
    font-size: 0.8rem;
    box-sizing: border-box;
  }

  .counter-container .MuiPaper-elevation1 h1 {
    font-size: 30px;
    margin: 0;
  }
}








.section--featured {
  perspective: 1000;
  -webkit-perspective: 1000;
  width: 100%;
  overflow: hidden;
  background: #0F0E10;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  min-height: 100vh;
  margin: 0;
  box-sizing: border-box;
  padding-top: 50px;
}

@media (max-width: 768px) {
  .section--featured {
    min-height: 110vh;
  }
}

.section--featured .row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .section--featured .col-sm-2 {
    -webkit-box-flex: 0;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

.section--featured .col-2 {
  -webkit-box-flex: 0;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.section--featured .row + .row {
  padding-left: 10px;
  margin-left: 10px;
}

.section--featured .shadow,
.section--featured .shadow span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.section--featured .shadow {
  background-image: linear-gradient(45deg, black, rgba(0, 0, 0, 0.3));
}

.section--featured .shadow span {
  background-image: linear-gradient(to top, #000, transparent);
}

@media (min-width: 768px) {
  .section--featured .shadow span {
    background-image: linear-gradient(0deg, black 0, rgba(0, 0, 0, 0.3) 70%));
    
  }
}

.section--featured img {
  margin: 0;
  width: 100%;
  display: block;
}

.section--featured .line {
  width: 100%;
  position: absolute;
  animation: marquee 100s linear infinite;
  top: -150px;
  z-index: 0;
}

.section--featured .line.second {
  top: 160px;
}

.section--featured .line.third {
  top: 470px;
}

.section--featured .line > div {
  width: 200%;
  height: 300px;
}

.section--featured .row-container {
  width: 2300px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: -1600px;
  transform: rotateX(25deg) rotateY(-10deg) rotateZ(11deg) translateX(50%) translateY(-45px);
  z-index: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .section--featured .row-container {
    transform: rotateX(25deg) rotateY(-10deg) rotateZ(12deg) translateX(50%) translateY(-50px);
  }
}

@media (min-width: 992px) {
  .section--featured .row-container {
    transform: rotateX(20deg) rotateY(-8deg) rotateZ(12deg) translateX(50%) translateY(-50px);
  }
}

.section--featured .line .row {
  width: 50%;
  float: left;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
